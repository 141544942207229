export default class Navigation {
  constructor(config = {}) {
    this.el = config.el || document.querySelector(".site-header");
    this.navbar = this.el.querySelector(".navbar");
    this.navbarToggle = this.el.querySelector(".navbar-toggle");
    this.init();
  }
  init() {
    this.toggleNav();
  }

  toggleNav() {
    const self = this;
    self.navbarToggle.addEventListener("click", function () {
      self.navbar.classList.toggle("navigation--toggled");
      document.body.classList.toggle('nav-is-open');
    });
  }

  onScrolling() {
    window.addEventListener("scroll", () => {
      this.stickyOnScroll();
    });
  }

  stickyOnScroll() {
    if (window.pageYOffset > 250) {
      this.el.classList.add("navbar-static-top");
    } else {
      this.el.classList.remove("navbar-static-top");
    }
  }
}
