import { keys, setAriaProp, isElementInViewport } from "../../utilities.js";
export default class Slider {
  constructor(config = {}) {
    this.el = config.el || document.querySelector(".slider");
    this.slides = this.el.querySelectorAll(".slider__slide");
    this.slidesLength = this.el.querySelectorAll(".slider__slide").length;
    this.track = this.el.querySelector(".slider__track");
    this.prevBtn = this.el.querySelector(".slider__prev-btn");
    this.nextBtn = this.el.querySelector(".slider__next-btn");
    this.buttonDisabledClass = "slider-button--disabled";
    this.sliderControlBtns = this.el.querySelector(".slider__controls-buttons");
    this.categorySlider = document.querySelector(
      ".slider.slider-category-card"
    );

    this.currentSlide = null;
    this.isgrabbing = false;
    this.isDown = false;
    this.startX;
    this.grabClass = "slider--grabbing";
    this.scrollLeft;
    this.scrollDirection = null;
    this.showAsslider = this.slidesLength > 1;
    this.isCompact = this.el.classList.contains("slider--compact");
    this.deviceWidth = window.innerWidth;

    this.init();
  }
  init() {
    this.el.classList.add("slider--inited");
    if (!this.showAsslider) {
      this.el.classList.add("slider--no-controls");
    }
    this.addEventListeners();

    if (this.showAsslider) {
      this.gotoSlide(0);
      this.setInactiveButtons(0);
      this.addAriaLiveElement();
      this.setAriaProps();
      this.hideControls();
    }

    if (this.categorySlider) {
      this.addHeaderHeight();
    }
  }

  addHeaderHeight() {
    this.categoryCardHeader = this.categorySlider.querySelectorAll(
      ".card__header"
    );

    const headerItems = [];

    this.categoryCardHeader.forEach((item) =>
      headerItems.push(item.clientHeight)
    );

    this.categoryCardHeader.forEach(
      (item) => (item.style.minHeight = Math.max(...headerItems) + "px")
    );
  }

  addAriaLiveElement() {
    var liveregion = document.createElement("div");
    liveregion.setAttribute("aria-live", "polite");
    liveregion.setAttribute("aria-atomic", "true");
    liveregion.setAttribute("class", "liveregion visuallyhidden");
    this.el.appendChild(liveregion);
  }
  addEventListeners() {
    const self = this;
    if (this.showAsslider) {
      this.track.addEventListener("scroll", (e) => {
        var slideInView = self.getSlideInViewIndex();
        self.setInactiveButtons(slideInView);
        setTimeout(() => {
          self.setAriaProps();
        }, 500);
      });
      if(this.prevBtn){
        this.prevBtn.addEventListener("click", (e) => {
          e.preventDefault();
          self.gotoPrevSlide();
        });
      }
      if(this.nextBtn){
        this.nextBtn.addEventListener("click", (e) => {
          e.preventDefault();
          self.gotoNextSlide();
        });
      }
    }
    document.onkeydown = function (evt) {
      evt = evt || window.event;
      if (self.el.classList.contains("js-focus-within")) {
        //right arrow
        if (evt.keyCode == keys.rightarrow) {
          self.gotoNextSlide();
        }
        //left arrow
        if (evt.keyCode == keys.leftarrow) {
          self.gotoPrevSlide();
        }
      }
    };
    // this.addGrabEvents();
  }
  hideControls() {
    if (this.el.classList.contains("slider--offset") && this.sliderControlBtns) {
      if (this.deviceWidth > this.track.scrollWidth) {
        this.sliderControlBtns.classList.add("visually-hidden");
      }
    }
  }
  getSlideInViewIndex() {
    let index = 0;
    var maxScrollLeft = this.track.scrollWidth - this.track.clientWidth;

    if (this.track.scrollLeft == 0) {
      //at first slide
      index == 0;
      // console.log("at begining");
    } else if (this.track.scrollLeft >= maxScrollLeft) {
      // console.log("at end");
      index = this.slidesLength - 1;
    } else {
      // in between
      // console.log("in between");
      index = Math.ceil(
        this.track.scrollLeft / (this.slides[0].scrollWidth + 10)
      );
    }
    this.currentSlide = index;
    return index;
  }
  isElementVisible(el) {
    const slideWidth = this.slides[1].scrollWidth;
    var index = Array.from(el.parentNode.children).indexOf(el);
    const scrollLeft = this.track.scrollLeft;
    const elLeft = index * slideWidth;
    const elRight = index * slideWidth + slideWidth;

    if (
      elLeft >= scrollLeft &&
      elRight <= scrollLeft + this.track.clientWidth
    ) {
      return true;
    } else {
      return false;
    }
  }
  setAriaProps() {
    for (let i = 0; i < this.slides.length; i++) {
      if (this.isElementVisible(this.slides[i])) {
        // this.slides[i].tabIndex = "0";
        setAriaProp(this.slides[i], "hidden", false);
        this.slides[i].classList.add("slider__slide--visible");
      } else {
        setAriaProp(this.slides[i], "hidden", true);
        this.slides[i].classList.remove("slider__slide--visible");
        // this.slides[i].tabIndex = "-1";
      }
      if (this.slides[i].classList.contains("slider__slide-offset")) {
        this.slides[i].tabIndex = "-1";
        setAriaProp(this.slides[i], "hidden", true);
      }
    }
  }
  addGrabEvents() {
    const self = this;
    this.el.classList.add("slider--has-grab-events");
    this.track.addEventListener("mousedown", (e) => {
      self.isDown = true;
      self.track.classList.add(self.grabClass);
      self.startX = e.pageX - self.track.offsetLeft;
      self.scrollLeft = self.track.scrollLeft;
    });
    this.track.addEventListener("mouseleave", () => {
      if (!self.isDown) return;
      self.gotoSlide(self.getSlideInViewIndex());
      self.isDown = false;
      // self.track.classList.remove(self.grabClass);
    });
    this.track.addEventListener("mouseup", () => {
      self.isDown = false;
      self.gotoSlide(self.getSlideInViewIndex());
      // self.track.classList.remove(self.grabClass);
    });
    this.track.addEventListener("mousemove", (e) => {
      if (!self.isDown) return;
      this.isgrabbing = true;
      e.preventDefault();
      const x = e.pageX - self.track.offsetLeft;
      self.walk = x - self.startX; //scroll-fast
      self.track.scrollLeft = self.scrollLeft - self.walk;
    });
  }
  setInactiveButtons(index) {
    var maxScrollLeft = this.track.scrollWidth - this.track.clientWidth;
    if(this.nextBtn && this.prevBtn){
      if (this.track.scrollLeft <= 20) {
        this.prevBtn.classList.add(this.buttonDisabledClass);
        this.prevBtn.tabIndex = "-1";

        this.nextBtn.classList.remove(this.buttonDisabledClass);
        this.nextBtn.tabIndex = "0";
      } else if (this.track.scrollLeft >= maxScrollLeft - 50) {
        this.nextBtn.classList.add(this.buttonDisabledClass);
        this.nextBtn.tabIndex = "-1";

        this.prevBtn.classList.remove(this.buttonDisabledClass);
        this.prevBtn.tabIndex = "0";
      } else {
        this.prevBtn.classList.remove(this.buttonDisabledClass);
        this.nextBtn.classList.remove(this.buttonDisabledClass);
        this.prevBtn.tabIndex = "0";
        this.nextBtn.tabIndex = "0";
      }
    }
  }
  gotoSlide(index, timeout = 0) {
    const self = this;
    this.track.classList.remove(self.grabClass);
    let scrollToX = this.slides[index].offsetLeft - 10;

    setTimeout(() => {
      self.track.scrollTo({
        top: 0,
        left: scrollToX,
        behavior: "smooth",
      });
    }, timeout);
  }
  gotoPrevSlide() {
    this.track.scrollBy({
      top: 0,
      left: -1 * this.slides[1].scrollWidth,
      behavior: "smooth",
    });
  }
  gotoNextSlide() {
    this.track.scrollBy({
      top: 0,
      left: this.slides[1].scrollWidth,
      behavior: "smooth",
    });
  }
}
