import "../../Styles/SubsiteWide/styles.less";
import Slider from "./components/slider/slider.js";
import CallToAction from "./components/cta/cta.js";
import CategoryPage from "./components/categorypage/categorypage.js";
import FavoritePost from "./components/favoritepost/favoritepost.js";
import ListFilterDropdown from "./components/listfilterdropdown/ListFilterDropdown.js";
import Navigation from "./components/navigation/navigation.js";
import CustomSelect from "./components/custom-select/custom-select.js";
import { initComponent } from "./utilities.js";

document.addEventListener("DOMContentLoaded", function () {
  initComponent(Slider, {
    el: ".slider",
  });

  initComponent(CallToAction, {
    el: ".cta-links",
  });

  initComponent(CategoryPage, {
    el: ".forum-category-page",
  });

  initComponent(FavoritePost, {
    el: ".favorite-post",
  });

  initComponent(Navigation, {
    el: ".site-header",
  });

  const customSelects = document.querySelectorAll(".custom-select");
  customSelects.forEach(select =>{
    CustomSelect.init(select);
  });
  
  initComponent(ListFilterDropdown, {
    el: ".list-filter-dropdown",
  });

})