export default class CategoryPage {
  constructor(config = {}) {
    this.el = config.el || document.querySelector(".forum-category-page");
    this.forumPost = this.el.querySelector(".forum-category");
    this.forumContent = this.el.querySelector(".forum-category-content");
    this.forumContentContainer = this.el.querySelector(
      ".forum-category-content-container"
    );
    this.contentToggle = this.el.querySelector(".show-more__button");
    if (this.contentToggle) {
        this.toggleText = this.contentToggle.querySelector(".show-more__title");
        this.toggleArrow = this.contentToggle.querySelector(".show-more__arrow");
        this.titleClosed = this.toggleText.dataset.closed;
        this.titleOpen = this.toggleText.dataset.open;
    }
    this.contentVisibleClass = "content--visible";
    this.contentOpenClass = "content--open";
    this.init();
  }

  init() {
    this.toggleContent();
    this.removeTabbableItems();
  }

  toggleContent() {
    const self = this;
    if(!self.contentToggle) return;
    self.contentToggle.addEventListener("click", function (e) {
      e.preventDefault();
      if (!self.forumPost.classList.contains(self.contentVisibleClass)) {
        self.forumPost.classList.add(self.contentVisibleClass);
        self.forumContent.classList.add(self.contentOpenClass);
        self.setTabbableItems();
        self.toggleText.innerHTML = self.titleOpen;
        self.contentToggle.name = self.titleOpen;
      } else {
        self.forumPost.classList.remove(self.contentVisibleClass);
        self.forumContent.classList.remove(self.contentOpenClass);
        self.toggleText.innerHTML = self.titleClosed;
        self.contentToggle.name = self.titleClosed;
        self.removeTabbableItems();
      }
    });
  }

  removeTabbableItems() {
    var links = this.forumContentContainer.querySelectorAll("a");

    links.forEach((link) => {
      link.setAttribute("tabindex", "-1");
      console.log(link.tabindex);
    });
  }

  setTabbableItems() {
    var links = this.forumContentContainer.querySelectorAll("a");

    links.forEach((link) => {
      link.setAttribute("tabindex", "0");
      console.log(link.tabindex);
    });
  }
}
