export default class CallToAction {
  constructor(config = {}) {
    this.el = config.el || document.querySelector(".cta-links");
    this.fixedClass = "cta-links--fixed";
    this.distTop = this.el.getBoundingClientRect().top;
    this.init();
  }

  init() {
    this.onScrolling();

    if (window.pageYOffset >= this.distTop) {
      this.stickyOnScroll();
    }
  }

  onScrolling() {
    window.addEventListener("scroll", () => {
      this.stickyOnScroll();
    });
  }

  stickyOnScroll() {
    const jsPrintPage = document.querySelector(".js-print-page");
    const contentActionMail = document.querySelector(".content-action-mail");
    const contentActionDel = document.querySelector(".content-action-del");

    if (window.pageYOffset >= this.distTop) {    
      this.el.classList.add("cta-links--fixed");
      document.querySelector("body").classList.add("cta-links--fixed");

      if (jsPrintPage) {
        jsPrintPage.setAttribute("tabindex", "-1");
      }
      if (contentActionMail) {
        contentActionMail.setAttribute("tabindex", "-1");
      }
      if (contentActionDel) {
        contentActionDel.setAttribute("tabindex", "-1");
      }
  } else {
      this.el.classList.remove("cta-links--fixed");
      document.querySelector("body").classList.remove("cta-links--fixed");

      if (!$("body").hasClass("captcha-open")) {
          if (jsPrintPage) {
              jsPrintPage.setAttribute("tabindex", "0");
          }
          if (contentActionMail) {
              contentActionMail.setAttribute("tabindex", "0");
          }
          if (contentActionDel) {
              contentActionDel.setAttribute("tabindex", "0");
          }
      }
    }
  }
}
