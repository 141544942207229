export default class ListFilterDropdown {
    constructor(config = {}) {
        this.el = config.el || document.querySelector(".list-filter-dropdown");
        this.realSelect = this.el.querySelector('.custom-list-filter');
        this.options = this.el.querySelectorAll(".ss-option");
        this.listContent = this.el.querySelectorAll(".list-filter-dropdown__category");
        this.resetBtn = this.el.querySelector(".list-filter-dropdown__reset-btn");
        this.init();
    }

    init() {
        this.select();
        this.reset();
    }

    select() {
        const self = this;

        self.options.forEach(function(option) {
            option.addEventListener("click", function() {
                self.showSelectedListContent(option);
        })});

        document.addEventListener('change', function(event) {
            if (event.target === self.realSelect) {
                self.clickTickedListElement();
            }
        })
    }

    reset() {
        const self = this;

        self.resetBtn.addEventListener("click", function() {
            self.showAllListContent();
        });

        self.resetBtn.addEventListener('keypress', function(event) {
            if (event.key === 'Enter') {
                self.showAllListContent();
            }
        }); 
    }

    showResetBtn() {
        const self = this;

        self.resetBtn.setAttribute('aria-hidden', false);
        self.resetBtn.style.display = null;
    }

    hideResetBtn() {
        const self = this;

        self.resetBtn.setAttribute('aria-hidden', true);
        self.resetBtn.style.display = "none";
    }

    showAllListContent() {
        const self = this;

        //Click default option
        self.options[0].click();

        self.listContent.forEach(function(content) {
            content.style.display = "block";
        });

        self.hideResetBtn();
    }

    showSelectedListContent(option) {
        const self = this;

        self.listContent.forEach(function(content) {
            if (content.getAttribute('data-list-id') === option.getAttribute('data-value')) {
                content.style.display = "block";
                content.setAttribute('aria-hidden', false);
            } else {
                content.style.display = "none";
                content.setAttribute('aria-hidden', true);
            }
        });

        self.showResetBtn();
    }

    clickTickedListElement() {
        const self = this;

        self.options.forEach(function (option) {
            if (option.classList.contains("ticked")) {
                option.click();
                return;
            }
        })
    }
}